import React from 'react';
import { Route } from 'react-router-dom';
import Home from './pages/Home';
import Aboutme from './pages/Aboutme';
import Articles from './pages/Articles';
import Books from './pages/Books';
import Professional from './pages/Professional';
import Media from './pages/Media';
import Menus from './components/Menus';
import './styles/main.scss';

const App = () => (
  <div data-test="AppComponent">
    <Menus />
    <Route path="/" exact component={Home} />
    <Route path="/aboutme" exact component={Aboutme} />
    <Route path="/books" exact component={Books} />
    <Route path="/articles" exact component={Articles} />
    <Route path="/professional" exact component={Professional} />
    <Route path="/media" exact component={Media} />
  </div>
);

export default App;
