export const cardinfo = [
  {
    url: '',
    text: 'Home',
    color: 'home',
    link: '/',
  },
  {
    url: 'About.jpg',
    text: 'About Me',
    link: '/aboutme',
  },
  {
    url: 'PB1.jpg',
    text: 'Books',
    link: '/books',
  },
  {
    url: 'Articles.jpg',
    text: 'Articles',
    link: '/articles',
  },
  {
    url: 'Professional.jpg',
    text: 'Professional',
    link: '/professional',
  },
  {
    url: 'Media.jpg',
    text: 'Media',
    link: '/media',
  },
];

export const contentinfo = [
  {
    category: 'aboutme',
    title: 'About Me',
    topimg: 'aboutme.jpg',
    contents: [
      {
        mainimg: 'aboutme-main.jpg',
        desc: `I am a tenured Full Professor of English Literature in the Department of English Language and Literature at Hanyang University in Seoul, South Korea. 
        I completed a Ph.D. in Comparative Literature and Cultural Studies at Monash University in 2002. 
        I then worked for nearly a decade as an academic in the United States, before moving to Seoul in 2010.<br/><br/>
        My research focuses mainly on modern and contemporary British fiction, Australian literature, and critical theory. 
        I am the author of three academic books in these fields: <i>Lacan the Charlatan</i> (2020), <i>English Magic and Imperial Madness</i> (2021), and <i>From Poet to Novelist: The Orphic Journey of John A. Scott</i> (2022). 
        I am currently working on a monograph about the Australian author Rodney Hall.<br/><br/>
        I have been an Honorary Research Fellow by the Global Center for Advanced Studies (GCAS) in Dublin since 2020. In 2021, I became the inaugural Director of the Hanyang Australia/New Zealand Studies (HANZS) Center. 
        My university presented me with an Outstanding Researcher Award in 2022, and again in 2023.
        `,
      },
    ],
  },
  {
    category: 'books',
    title: 'Books',
    topimg: 'booktop.jpg',
    contents: [
      {
        mainimg: 'PB3.jpg',
        imgLink: 'https://www.cambriapress.com/pub.cfm?bid=871',
        desc: `From Poet to Novelist: The Orphic Journey of John A. Scott (Cambria Press, 2022)<br/><br/><br/>
        John A. Scott began his literary life as a poet, but a fellowship in Paris persuaded him to write novels instead. 
        The move was a success, with Scott’s fiction winning the Victorian Premier’s Literary Award and being shortlisted twice for the Miles Franklin Award. 
        This book aims to illuminate his texts by guiding the reader through some of the key ideas and influences that have informed his Orphic journey from poet to novelist.
        <br/><br/><i>From Poet to Novelist: The Orphic Journey of John A. Scott</i> is available from Cambria Press in the 
        <strong><a href='https://www.cambriapress.com/pub.cfm?bid=871' target='_blank'>Australia Literature Series</a></strong>.`,
      },
      {
        mainimg: 'PB2.jpg',
        imgLink:
          'https://mcfarlandbooks.com/product/english-magic-and-imperial-madness/',
        desc: `English Magic and Imperial Madness (McFarland Books, 2021)<br/><br/><br/>
        After disappearing for centuries, magic returns unexpectedly to Regency England in Susanna Clarke’s fantasy novel <i>Jonathan Strange and Mr. Norrell</i> (2004). 
        This inaugural study of Clarke’s groundbreaking work locates the consequences of this eruption of magical unreason in the context of England’s imperial history. 
        Examining <i>Jonathan Strange and Mr. Norrell</i> in the larger historical context of magic and its links to colonialism, <i>English Magic and Imperial Madness</i> challenges its readers to rethink the connections between national identity, rationality, and power.
       <br /><br /><i>English Magic and Imperial Madness</i> is available from McFarland Books in the 
       <Strong><a href='https://mcfarlandbooks.com/product/english-magic-and-imperial-madness/'
            target='_blank'> Critical Explorations in Science Fiction and Fantasy Series</a></Strong>.`,
      },
      {
        mainimg: 'PB1.jpg',
        imgLink: 'https://link.springer.com/book/10.1007/978-3-030-45204-9',
        desc: `Lacan the Charlatan (Palgrave Macmillan, 2020)<br/><br/><br/>
        This book sets out to determine the validity of an accusation made against Jacques Lacan by Noam Chomsky in an interview in 1989. 
        He stated that Lacan was a “charlatan” – not that his ideas were flawed or wrong, but that his entire discourse was fraudulent, an accusation that has since been repeated by many other critics. 
        Examining the arguments of key anti-Lacanian critics, Mathews weighs and contextualizes the legitimacy of Lacan’s engagements with structural linguistics, mathematical formalization, science, ethics, Hegelian dialectics, and psychoanalysis. 
        The guiding thread is Lacan’s own recurrent interrogation of authority, which inhabits an ambiguous zone between mastery and charlatanry. This book offers a novel contribution to the field for students and scholars of psychoanalysis, philosophy, sociology, critical and literary theory.
        <br/><br/><i>Lacan the Charlatan</i> is available from Palgrave Macmillan in the <Strong><a href='https://link.springer.com/book/10.1007/978-3-030-45204-9'
        target='_blank'>Palgrave Lacan Series</a></Strong>`,
      },
    ],
  },
  {
    category: 'articles',
    title: 'Articles',
    topimg: 'articletop.jpg',
  },
  {
    category: 'professional',
    title: 'Professional',
    topimg: 'professionaltop.jpg',
    contents: [
      {
        mainimg: '',
        desc: `<strong>EDUCATION</strong><br/><br/> Ph.D. in Comparative Literature and Cultural Studies, Monash University (Melbourne, Australia), 2002.<br/><br/>
        B.A. (Hons) in Comparative Literature and Cultural Studies, Monash University, 1997. <br/><br/>
        <br/><br/><strong>EMPLOYMENT</strong>
        <br/><br/>Professor of English Literature, Hanyang University (Seoul, South Korea), 2017-present<br/><br/>Associate Professor of English Literature, Hanyang University, 2012-2017.<br/><br/>Assistant Professor of English Literature, Hanyang University, 2010-2012.<br/><br/>Assistant Professor of English, Centenary University (Hackettstown NJ, USA), 2005-2010.<br/><br/>Visiting Assistant Professor of English, Creighton University (Omaha NE, USA), 2003-2005.<br/><br/>Lecturer in English, University of Nebraska-Lincoln (Lincoln NE, USA), 2002-2003.<br/><br/>
        <br/><br/><strong>SPECIAL APPOINTMENTS</strong><br/><br/>
        Director, Hanyang Australia/New Zealand Studies (HANZS) Center, 2021-present<br/><br/>
        Honorary Research Fellow, Global Center for Advanced Studies (GCAS) in Dublin, 2020-present<br/><br/>
        <br/><br/><strong>ASSOCIATIONS</strong><br/><br/>American Association of Australian Literary Studies (AAALS)<br/><a href='http://aaals.org/'  target='_blank' style='color:#ff4d00'>http://aaals.org/</a><br/><br/>British Association for Contemporary Literary Studies (BACLS)<br/><a href='https://www.bacls.org/' target='_blank' style='color:#ff4d00'>https://www.bacls.org/</a><br/><br/>European Association for Commonwealth Literature and Language Studies (EACLALS)<br/><a href='https://eaclals.eu/' target='_blank' style='color:#ff4d00'>https://eaclals.eu/</a><br/><br/>European Association for Studies of Australia (EASA)<br/><a href='http://www.australianstudies.eu/' target='_blank' style='color:#ff4d00'>http://www.australianstudies.eu/</a><br/><br/>Jane Austen Society of North America (JASNA)<br/><a href='http://jasna.org/' target='_blank' style='color:#ff4d00'>http://jasna.org/</a><br/><br/>Modern Language Association (MLA)<br/><a href='https://www.mla.org/' target='_blank' style='color:#ff4d00'>https://www.mla.org/</a><br/><br/>Philip Roth Society<br/><a href='https://www.philiprothsociety.org/' target='_blank' style='color:#ff4d00'>https://www.philiprothsociety.org/</a><br/><br/>Société d'Etudes Anglaises Contemporaines (SEAC)<br/><a href='http://www.laseac.fr/' target='_blank' style='color:#ff4d00'>http://www.laseac.fr/</a>`,
      },
    ],
  },
  {
    category: 'media',
    title: 'Media',
    topimg: 'mediatop.jpg',
    contents: [
      {
        mainimg: 'Media.jpg',
        desc: `
        <div style="display:flex;flex-direction:column;"><a href="https://soundcloud.com/podcast-co-coopercherry/peter-mathews-lacan-the-charlatan" target='_blank'><img src="/images/soundcloud_icon.png" alt='YouTube link' style="width:70px;height:auto;"></a>
        <div>Interview with Cooper Cherry and Taylor Adkins about <i>Lacan the Charlatan</i>, October 24, 2021</div><br/><br/></div>
        `,
      },
      {
        mainimg: 'PM1.jpg',
        desc: `
        <div style="display:flex;flex-direction:column;">
        <a href="https://www.youtube-nocookie.com/embed/vCGfNWd2xFg" target='_blank'><img src="/images/youtube-logo.png" alt='YouTube link' style="width:70px;height:auto;"></a>
        <div>Interview with Eliot Rosenstock about <i>Lacan the Charlatan</i>, August 13, 2020</div>
        </div>
        `,
      },
    ],
  },
];
