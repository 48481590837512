export const articlesInfo = [
  {
    category: 'Journal Articles',
    info: [
      {
        title:
          'The Pleasures of Unpleasure: Jacques Lacan and an Atheism Beyond the ‘Death of God’',
        published: 'Filozofski Vestnik 43.3 (2022)',
        image: 'A43.jpg',
        desc: `Although the desire to be free from God springs from humanity’s wish to enjoy pleasure without restraint, Lacan observes that humans remain neurotic and unhappy. 
          That is because the prevailing “death of God” form of atheism relies on the denial of a father/god, a negation that inadvertently replicates the logic of religion. Lacan, by contrast, grounds his atheism in a theory of pleasure that recognizes the role of “unpleasure” in breaking the tedium of easy, unlimited gratification. 
          Turning to Greek tragedy, Lacan shows how the ancient world used the gods as creators of “unpleasure” to generate human <i>jouissance</i>. The figure of Antigone, in particular, shows how the divine function can fulfill “the true formula of atheism,” which is not “God is dead,” but rather, Lacan affirms, that “God is unconscious.”<br/><br/>
          Journal: Filozofski Vestnik<br/>
          Issue: 43.3<br/>
          Publisher: Slovenian Academy of Sciences and Arts<br/>
          Year: 2022<br/>
          ISSN: 0353-4510<br/>
          `,
      },
      {
        title:
          'The Monstrosities of Modernity: Baudelaire’s Legacy in Alex Landragin’s <i>Crossings</i>',
        published: 'Westerly 67.2 (2022)',
        image: 'A44.jpg',
        desc: `This essay examines the legacy of Charles Baudelaire on the novel <i>Crossings</i> (2019) by the French-Australian author Alex Landragin, a book in which Baudelaire appears as a fictional character. 
          The thematic focus of the essay is on the notion of monstrosity – Baudelaire’s section is titled “The Education of a Monster” – and starts with his claim that the “ennui” of the modern world is a “dainty monster” that, to escape its clutches, entices us to commit evil. 
          The essay places this idea in the context of Landragin’s portrayal of the French discovery of the fictional Pacific island of Oaeetee, in which this foreign intrusion results in a series of disasters for the island people that leads them to experience all the monstrosities of colonization. 
          The essay then delves more deeply into how particular texts by Baudelaire inform this critique of modern life, with particular importance laid on the influence of the short story “The Eyes of the Poor” on Landragin’s novel. 
          The conclusion analyses how Baudelaire not only identifies the ethical compromises inherent in modern life, but also hints at a revolutionary democratic potential that might be attained by using empathy to overcome its structural injustices, a political vision that continues both in Walter Benjamin (another of the novel’s narrators) and in Landragin’s ground-breaking text.<br/><br/>
          Journal: Westerly<br/>
          Issue: 67.2<br/>
          Publisher: Westerly Centre (University of Western Australia)<br/>
          Year: 2022<br/>
          ISSN: 0043-432X<br/>
          `,
      },
      {
        title: 'The Moral Economy of Sadie Jones’s <i>The Snakes</i>',
        published: 'English Studies 103.6 (2022)',
        image: 'A45.jpeg',
        desc: `Sadie Jones’s fifth novel <i>The Snakes</i> (2019) examines the overlap between secular capitalism and religious thought, simultaneously evoking and undermining sinful associations with the serpent from Genesis. 
          Jones contemplates how Christianity and capitalism have shaped the moral economy, blending wealth and virtue to create a modern system of values. 
          The essay first examines the responses of Jones’s characters to this system: the protagonist, Bea, turns her back on her family’s fortune to live the principled life of a saint, whereas her brother Alex sees himself as a fallen sinner, drawn to the annihilation of self he reads about in Keats, Rumi, and Weil. 
          The essay’s second half focuses on how Jones disrupts this economy by challenging the association of snakes with evil by strategically incorporating elements from ancient Greek culture. The Greek gods, for instance, reject the transactional logic that underpins capitalism, regarding it as a hubristic challenge to divine sovereignty. 
          Just as Bea’s family wealth cannot prevent Alex’s fatal car accident, so too Bea’s virtue cannot stop her brutal murder. No amount of credit, moral or financial, can move the amoral sovereignty of the world, as implacable as the rocks and trees Bea contemplates in her final moments.<br/><br/>
          Journal: English Studies<br/>
          Issue: 103.6<br/>
          Publisher: Taylor & Francis<br/>
          Year: 2022<br/>
          ISSN: 0013-838X<br/>
          `,
      },
      {
        title:
          'The Peace Offering: Concluding Steven Carroll’s <i>Eliot Quartet</i>',
        published: 'Westerly 67.1 (2022)',
        image: 'A46.jpeg',
        desc: `This article examines Steven Carroll's novel <i>Goodnight</i>, <i>Vivienne</i>, <i>Goodnight</i> (2022), which concludes his <i>Eliot Quartet</i>, four novels that consider the life and legacy of T.S. Eliot through the prism of his <i>Four Quartets</i>.<br/><br/>
          Journal: Westerly<br/>
          Issue: 67.1<br/>
          Publisher: Westerly Centre (University of Western Australia)<br/>
          Year: 2022<br/>
          ISSN: 0043-432X<br/>
          `,
      },
      {
        title:
          'A.S. Byatt’s ‘On the Day That E.M. Forster Died’ and the Ambivalence of Connection',
        published: 'Journal of the Short Story in English 76 (2021)',
        image: 'A47.jpeg',
        desc: `In her short story “On the Day That E.M. Forster Died,” first published in 1983 and included in her 1987 collection Sugar and Other Stories, A.S. Byatt uses her narrator, Mrs. Smith, to examine the limitations of Forster’s famous exhortation “only connect.” 
          Byatt’s reservations stretch back to her earliest days as a writer, from the skepticism she expresses about the formal restrictions of the modernist novel in her introduction to <i>The Shadow of the Sun</i>, to her reflections on her longstanding struggle with the assumptions of Leavisite criticism that shaped her early mindset as a literary scholar. 
          What is particularly irksome for her about this philosophy is that it imposes on the reader (especially the female reader) an imperative to connect that leaves no freedom to disconnect, to choose and develop a space of one’s own. 
          Using Byatt’s short story as its starting point, this essay will examine how Byatt develops a pointed contrast, as she sees it, between the connective imperatives of Forster and D.H. Lawrence, on the one hand, and George Eliot and Iris Murdoch, who open up a space for freedom and disconnection, on the other.<br/><br/>
          Journal: Journal of the Short Story in English<br/>
          Issue: 76<br/>
          Publisher: Presses universitaires de Rennes<br/>
          Year: 2021<br/>
          ISSN: 1969-6108<br/>
          `,
      },
      {
        title: 'The Symbolism of Clothing: The Naked Truth About Jacques Lacan',
        published: 'CLCWeb: Comparative Literature and Culture 23.1 (2021)',
        image: 'A40.jpg',
        desc: `In the work of Jacques Lacan there exists an extended metaphor of clothing, whereby the ‘naked’ truth is always ‘clothed’ in deception. 
          For Lacan, clothing functions at the intersection of the symbolic and the imaginary, with outward appearance shaping what we imagine to be underneath in order to determine the landscape of symbolic desire. 
          Joan Copjec considers the political implications of this metaphor, arguing that utilitarianism, in particular, divides desire into a false dichotomy of rational, naked desire, and the ornamental clothing of irrationality, a mindset woven into both capitalism and French colonialism. 
          The article then examines two examples from Lacan’s commentary on ethics: the story of Saint Martin and the beggar, and the myth of Actaeon and Diana, further demonstrating how clothing metaphors are entwined with notions of truth and deception. 
          The article concludes by considering whether Lacan’s words are similarly clothed in deception, and ponders whether he should be telling us the naked truth. Lacan weighs both options and concludes that the most effective form of communication constitutes a double bluff in which he strategically pretends to lie, not for the sake of deception, but in order to ‘clothe’ (and thus protect) the naked truth concealed underneath.
          <br/><br/>
          Journal: CLCWeb: Comparative Literature and Culture<br/>
          Issue: 23.4<br/>
          Publisher: Purdue University Press<br/>
          Year: 2021<br/>
          ISSN: 1481-4374<br/>
          `,
      },
      {
        title: 'Hacking the Society of Control: The Fiction of Hari Kunzru',
        published:
          'Critique: Studies in Contemporary Fiction 62.5 (2021): 620-630',
        image: 'A41.jpg',
        desc: `The prevailing tendency among critics is to read the fiction of Hari Kunzru through a postcolonial lens, emphasizing either his themes of fluidity and hybridity, or his cosmopolitan resistance to national boundaries. 
        This essay takes a different approach by examining how Kunzru engages notions of political theology. Kunzru uses computer programming, for instance, as a metaphor for divine creation, so that in works like <i>Transmission</i> and <i>Gods Without Men</i> his protagonists, both programmers, confront the notion of hacking the divine “code.” In <i>Gods Without Men</i>, this process is achieved through a computer program named Walter that is able to predict first the stock market, and then the course of historical events. 
        Walter is named after Walter Benjamin, who argued that a concealed political theology underlies the logic of modern society. Following similar clues in Kunzru’s fiction, this essay explores his diagnosis, on the one hand, of political theology’s responsibility for the creation of a modern society of control, and the revolutionary potential of being able to subvert and manipulate that divine code, on the other. 
        In a world where the hegemony of modern capitalism feels increasingly stifling, Kunzru offers a means of escape and resistance by turning society’s code against itself.
        <br/><br/>
        Journal: Critique: Studies in Contemporary Fiction<br/>
        Issue: 62.5<br/>
        Publisher: Taylor & Francis<br/>
        Year: 2021<br/>
        ISSN: 0011-1619<br/>
        `,
      },
      {
        title:
          'Prophetic Time and the End of Capitalism in Iain Pears’s Stone’s Fall',
        published:
          'English Studies 102.1 (2021): 67-84. (Co-authored with Minjung Park)',
        image: 'A37.jpg',
        desc: "This essay sets out to examine the connection between capitalism and ideas about time, focusing on Iain Pears's novel Stone's Fall (2009). Capitalism, with its endless promises of future returns, tends to deploy a model of time oriented around progress toward the future. Pears's narrative, by contrast, subverts this by reversing the timeline, each section of the novel a step backwards in time. The first section of the paper examines how other critics, from Walter Benjamin to Mary Poovey, have used similar reversals to undertake critical genealogies of capitalism's emergence. The second section shows how fiction and finance intersect in the credit economy, blending ethics and economies to train subjects who are, in Nietzsche's words, capable of making promises about the future. The essay closes by looking at the notion of prophetic time, which disrupts the capitalist paradigm through the unexpected return of ancient tragedy in the modern novel.<br/><br/>Journal: English Studies<br/>Issue: 102.1<br/>Publisher: Taylor & Francis<br/>Year: 2021<br/>ISSN: 0013-838X<br/>",
      },

      {
        title: 'Brian Castro – Mon Semblable! – Mon Frère!',
        published:
          'Antipodes: A Global Journal of Australian/New Zealand Literature 34.1 (2020): 45-56.',
        image: 'A42.jpg',
        desc: `This essay looks at the significance of the <i>semblable</i>, the figure of the double or likeness, which Brian Castro borrows, in this instance, from Baudelaire. 
        The similarity of the semblable helps to draw out in Castro’s fiction not only what is familiar but also, in an uncanny move, what is unfamiliar about the world around us, its essential foreignness. 
        Castro’s fascination with the foreign springs from a double ethical impetus: first, as an outsider’s refusal of the racist prejudices of mainstream society and, second, as a recognition of the radical contingency that underpins all existence, making each of us a foreigner, as it were, in the world. 
        The essay then considers the recurrent tendency in Castro’s novels to “overwrite” the stories of others in ways that similarly blur the line between self and Other: O’Young’s translation of Shan in <i>Birds of Passage</i>, for instance, or Artie Catacomb’s attempts to hijack the story of Sergei Wespe in <i>Double-Wolf</i>, 
        with each antagonist becoming the double (or <i>semblable</i>) of his counterpart. Finally, the essay examines the normalizing function of the gaze, with Castro borrowing from Sartre and Lacan, such as the latter’s discussions of anamorphosis in <i>Pomeroy</i>. Castro demonstrates in his fiction how this gaze can be subverted and confused by the subject’s adoption of a second (or even multiple) identities, a deliberate cultivation of the <i>semblable</i> that helps to protect the vulnerable outsider from the normalizing power of the gaze.<br/><br/>
        Journal: Antipodes: A Global Journal of Australian/New Zealand Literature<br/>
        Issue: 34.1<br/>
        Publisher: Wayne State University Press<br/>
        Year: 2020<br/>
        ISSN: 0893-5580<br/>`,
      },
      {
        title:
          'The Scrambled Script: Contingency and Necessity in Iris Murdoch’s The Green Knight',
        published: 'Études britanniques contemporaines 59 (2020)',
        image: 'A38.jpg',
        desc: "This paper begins at the rock in Switzerland where Nietzsche came up with his theory of eternal return, with a promise that its connection to Murdoch’s The Green Knight will be clear by the end. The eternal return introduces the key theme of repetition, from Peter Mir’s desire to re-enact his ‘death’, to Harvey’s return to the Italian bridge where he hurt his foot. Murdoch also introduces repetition through her allusions, such as the titular reference to Sir Gawain and the Green Knight Murdoch subverts such textual precursors by ‘scrambling’ their messages, twisting their meanings and mixing them up in a way that removes the possibility of a single, coherent meaning. This ‘scrambling’ reflects Murdoch’s concern with the dual roles of contingency and necessity in human existence. While Murdoch draws on various philosophical and literary influences—Schopenhauer, Plato, even Nabokov—this paper argues that Nietzsche is a particularly important interlocutor here, especially his influence on Lucas and Sefton’s critical view of historicism. The final section examines the novel’s explicit references to Nietzsche’s eternal return, culminating with the scene where Moy returns a stone to its ‘rightful’ place near Bellamy’s seaside cottage. This is Murdoch’s ultimate caricature of the human desire to imagine patterns and order in the universe—indeed, the place where Moy carries this out is described in the exact same terms as the rock where Nietzsche discovered the eternal return, creating yet another repetition, and at the same time, another scrambled form.<br/><br/>Journal: Études britanniques contemporaines<br/>Issue: 59<br/>Publisher: Société d'Etudes Anglaises Contemporaines<br/>Year: 2020<br/>ISSN: 2271-5444<br/>",
      },
      {
        title:
          'The Ambivalence of Tradition in Rachel Kadish’s The Weight of Ink',
        published:
          'Concentric: Literary and Cultural Studies 46.1 (2020): 155-172.',
        image: 'A39.jpg',
        desc: "The ambivalence at the heart of Rachel Kadish's third novel, The Weight of Ink (2017), comes from the tension between Kadish's exploration of Jewish identity, on the one hand, and her position as a feminist on the other. Kadish thus uses the form of the historical novel to critique patriarchal traditions in Jewish culture that exclude women from the domain of scholarship and philosophical thought. The Weight of Ink weaves a complex narrative contrasting the life of a twenty-first-century historian, Helen Watt, to the fierce intellect of Ester Velazquez, a female scribe from the seventeenth century, who is forced to develop her philosophy secretly under a series of male pseudonyms. This essay starts out by examining the delicate balance required for this kind of cultural critique, for while Kadish excoriates the past ill-treatment of women and homosexuals, she nonetheless is also clearly proud of her identity as a Jewish woman. The second section of this paper looks at the extent to which Kadish launches a successful feminist analysis of Jewish culture and traditions. Her strongest points come when she turns the logic of this tradition back on itself: the very existence of Ester's intellect or Alvaro's homosexuality, she argues, are proof that such things are ordained by divine will rather than its contradiction. The conclusion of the paper explores the limitations of Kadish's approach, focusing in particular on the way she creates a biological tie between Ester and Shakespeare. This narrative twist appropriates Ester to the realm of the patriarchal law, encapsulating the irresolvable tension between Kadish's Jewishness and her feminist politics.<br/><br/>Journal: Concentric: Literary and Cultural Studies<br/>Issue: 46.1<br/>Publisher: National Taiwan Normal University<br/>Year: 2020<br/>ISSN: 1729-6897<br/>",
      },
      {
        title: 'Embodied Art: A Reading of A.S. Byatt’s ‘Body Art’',
        published:
          'English: Journal of the English Association 68.263 (2019): 344-363.',
        image: 'A1.jpg',
        desc: 'This article examines the idea of an embodied art in A.S. Byatt’s short story ‘Body Art’. In order to contextualize this concept, the essay begins with a survey of Byatt’s earlier explorations of the link between mind and body, as well as an analysis of the small amount of secondary material relating to ‘Body Art’, a text that has received little critical attention. The article then explores the story’s ties to Dutch vanitas painting, a tradition that is intimately linked to the study of anatomy. The vanitas tradition shows how medicine and art were once a unified field, and explores the consequences of their modern division. This leads to a consideration of the influence of theological debates about mind and body and their effect, in particular, on Renaissance humanist art. The next section examines the shifting meaning of the archival collection, particularly in its significance for modern formations of subjectivity. This idea is particularly important in the context of the story’s allusions to Joseph Beuys, who views the artist’s body as a locus of creativity. Like Beuys, Byatt is interested in art that draws on the imaginative power of religious storytelling and imagery while rejecting its supernatural elements. Byatt draws together all of these elements in her story in order to articulate her vision of an embodied art, one that draws together the conceptual and the physical.<br/><br/>Journal: English: Journal of the English Association<br/>Issue: 68.263<br/>Publisher: Oxford University Press<br/>Year: 2019<br/>ISSN: 0013-8215',
      },
      {
        title: 'Tim Winton and the Ethics of the Neighbor Here and Now',
        published: 'Journal of Postcolonial Writing 55.5 (2019): 642-655.',
        image: 'A2.jpg',
        desc: 'This article explores the ethical concept of the neighbour, an idea central to the fiction of Tim Winton. The first part focuses on how the ghosts in Cloudstreet symbolize an Australian culture haunted by the injustices of colonization, especially the dispossession of the Indigenous people. The second part looks at the paradox of being commanded to love one’s neighbour, comparing an early story, ‘Neighbours’, to Winton’s recent novel Eyrie. The third part looks at Winton’s ethics of neighbourliness in light of recent critical reworkings of this concept by Slavoj Žižek and Kenneth Reinhard. Central to this section is the importance of time and place to the ethics of the neighbour, in particular the repeated insistence by both Winton and his critics that, rather than focusing on the past, we should acknowledge the neighbour who stands before us in the here and now.<br/><br/>Journal: Journal of Postcolonial Writing<br/>Issue: 55.5<br/>Publisher: Taylor & Francis<br/>Year: 2019<br/>ISSN: 1744-9855',
      },
      {
        title:
          'Boochani Bound: A Promethean Meditation on Refugee Detention Camps',
        published: 'Westerly 64.1 (2019): 59-71.',
        image: 'A3.jpg',
        desc: 'This paper examines the mechanisms of power in Behrouz Boochani’s memoir No Friend But the Mountains (2018), placing it in the context of key literary references such as Kafka and the Marquis de Sade. Comparing Boochani’s narrator to the ancient figure of Prometheus, whose words of truth are brutally negated by the power of Zeus’s will, the essay ponders the problem of how to negotiate injustice in the face of arbitrary oppression.<br/><br/>Journal: Westerly<br/>Issue: 64.1<br/>Publisher: Westerly Centre (University of Western Australia)<br/>Year: 2019<br/>ISSN: 0043-432X',
      },
      {
        title: 'Dynamic Tensions in A.S. Byatt’s ‘A Lamia in the Cévennes’',
        published:
          'Critique: Studies in Contemporary Fiction 59.2 (2018): 213-222.',
        image: 'A4.jpg',
        desc: 'In order to understand A.S. Byatt’s short story ‘A Lamia in the Cévennes,’ from her collection Elementals: Stories of Fire and Ice (1998), it is necessary to understand the principle of dynamic tension that she employs in her work. As such, the initial part of this paper examines how Byatt engages in a constant process of critical examination using examples that prove pertinent to the story, such as her critique of D.H. Lawrence’s cosmological dichotomies of sun (masculine) and moon (feminine), her playful subversion of gender and sexuality in Possession, the deconstruction of postmodern theory in The Biographer’s Tale, and her fascination with retelling and subverting the myth of Melusine. The second part of the paper analyzes how these dynamic tensions manifest themselves in the story in question, from Byatt’s critique of Keats’s romanticism, to her subversion of the line between myth and reality, her examination of artistic realism and abstraction through the story’s references to David Hockney, and her reframing of all these questions in terms of problematic cultural assumptions about gender and sexuality. Altogether, these examples demonstrate the critical mindset at work in Byatt’s fiction, which never flinches from confronting the complexities that necessarily co-exist in life and art.<br/><br/>Journal: Critique: Studies in Contemporary Fiction Issue: 59.2<br/>Publisher: Taylor & Francis<br/>Year: 2018<br/>ISSN: 0011-1619',
      },
      {
        title:
          'Disillusionment at the Caffè Florian: Ishiguro’s ‘Cellists’ and the Destruction of Experience',
        published:
          'Caesura: Journal of Philological and Humanistic Studies 4.2 (2017): 17-30.',
        image: 'A5.jpg',
        desc: 'This paper examines what Agamben calls the ‘destruction of experience’ – the downgrading of lived experience as truth in favor of empirical knowledge in the modern world – as it manifests itself in Kazuo Ishiguro’s short story ‘Cellists,’ the final piece in his collection Nocturnes (2009). The first half of the paper examines how the narrative situates Tibor, the story’s main character, within a complex set of symbols that reflect on the question of experience and repetition. Through the theoretical angle supplied by Agamben, I look at how the diminished status of lived experience in ‘Cellists’ repeats familiar patterns from Ishiguro’s other fiction, in particular his use of unreliable narrators and the ruthless utilitarianism of many of his American characters. The second half of the paper contemplates how this attitude produces a mood of disillusionment that is drawn, in particular, from the story’s setting in the Caffè Florian in Venice, with Ishiguro borrowing this sentiment from a long tradition that includes Henry James’s The Aspern Papers, Honoré de Balzac’s Massimilla Doni, and Thomas Mann’s short story ‘Disillusionment.’ By examining Ishiguro’s short story in the context of these three texts, the paper looks at how Florian’s has functioned repeatedly in the literary imagination as a site of reflection on the nature of experience and disillusionment.<br/><br/>Journal: Caesura: Journal of Philological and Humanistic Studies<br/>Issue: 4.2<br/>Publisher: Emanuel University Press<br/>Year: 2017<br/>ISSN: 2360-3372',
      },
      {
        title: 'What Are Novelists For?',
        published:
          'Atonement and the British Novel” Atlantis: Journal of the Spanish Association for Anglo-American Studies 39.2 (2017): 11-28.',
        image: 'A6.jpg',
        desc: 'This essay emerged from the intersection of two texts: a 2009 article by Alistair Cormack claiming that Atonement was a rejection of postmodernism in favor of a return to F.R. Leavis’s Great Tradition, and the protagonist Briony’s closing question: ‘What are novelists for?’ This essay criticizes the ongoing legacy of Leavis’s association of literature and moral improvement, an argument still being recycled today by critics like Harold Bloom and Martha Nussbaum, by tracing McEwan’s long history of interrogating this presumed ethical link in his fiction. Far from affirming Leavis’s position, McEwan’s work shows that some of humanity’s worst atrocities have coincided with its greatest period of education and literacy. Rather than a moral phenomenon, the concluding section of the essay draws on the recent work of Nancy Armstrong, among others, to argue that the novel reflects the production of a peculiarly modern form of subjectivity that allows Atonement, by combining postmodern strategies with references to seminal texts from the British tradition (Richardson, Fielding, Burney, Austen, Woolf), to reveal the obscured roots of what gave birth to the novel in the first place.<br/><br/>Journal: Atlantis: Journal of the Spanish Association for Anglo-American Studies<br/>Issue: 39.2<br/>Publisher: Asociación Española de Estudios Anglo-Norteamericanos (Spanish Association for Anglo-American Studies)<br/>Year: 2017<br/>ISSN: 0210-6124',
      },
      {
        title: 'Unraveling A.S. Byatt’s ‘Racine and the Tablecloth’',
        published: 'Contemporary Women’s Writing 11.2 (2017): 221-238.',
        image: 'A7.jpg',
        desc: 'A. S. Byatt’s story ‘Racine and the Tablecloth’ (1987) has received little critical attention, even though it appears at a pivotal moment in her career. Nonetheless, Byatt herself highlighted its importance when she published ‘Arachne’ (2000), a reflection on Ovid that further articulates the inspirations and themes of the earlier story. Considering these two texts as companion pieces, this article sets out to unravel Byatt’s metaphor of the text as a fabric woven together from heterogeneous threads, first by setting this concept in the context of Roland Barthes’s theories about textuality, then by tracing how ‘Racine and the Tablecloth’ is itself spun from a multiplicity of sources and ideas that exist in dynamic tension: Byatt’s own experiences, Euripides, Ovid, and of course, Racine.<br/><br/>Journal: Contemporary Women’s Writing<br/>Issue: 11.2<br/>Publisher: Oxford University Press<br/>Year: 2017<br/>ISSN: 1754-1484',
      },
      {
        title: 'Gender, Power, and Satire in Will Self’s Cock and Bull',
        published:
          'English: Journal of the English Association 66.253 (2017): 166-184.',
        image: 'A8.jpg',
        desc: 'While Will Self’s Cock and Bull has rightly been seen as a satirical critique of gender and sexuality, this article argues that critics have tended to overlook the intertwining critique of power that accompanies such themes. Placing Cock and Bull within the larger context of Self’s subsequent writings and intellectual influences, this essay examines how these two early novellas self-consciously borrow from and engage with the tradition of British satire, which has long been concerned with questions of gender, sexuality, and power.<br/><br/>Journal: English: Journal of the English Association<br/>Issue: 66.253<br/>Publisher: Oxford University Press<br/>Year: 2017<br/>ISSN: 0013-8215',
      },
      {
        title:
          'Art, Beauty, and the Problem of Consciousness in Tim Winton’s Breath',
        published: 'Westerly 62.1 (2017): 219-230.',
        image: 'A9.jpg',
        desc: 'Drawing inspiration from Giorgio Agamben’s The Man Without Content, this essay analyzes issues of art and beauty in Tim Winton’s novel Breath (2008). Challenging the Kantian notion of aesthetics, which focuses attention on the viewer of spectator of art, this paper shows how Winton reverses this attitude: art is instead considered from the perspective of the artist, divorced from any notion of audience, in order to explore a new way of thinking about beauty and existence.<br/><br/>Journal: Westerly<br/>Issue: 62.1<br/>Publisher: Westerly Centre (University of Western Australia)<br/>Year: 2017<br/>ISSN: 0043-432X',
      },
      {
        title:
          'Who is My Neighbour?: Tim Winton’s ‘Aquifer’ and the Ghosts of Cloudstreet',
        published: 'Australian Literary Studies 32.1 (2017).',
        link: 'https://doi.org/10.20314/als.79ce443cbd',
        image: 'A10.jpg',
        desc: 'The psychology of guilt as debt is a recurrent theme in Tim Winton’s fiction. A number of scholars have recently examined the theme of haunting in Winton’s Cloudstreet (1991), arguing that the ghosts which appear in the story represent an engagement with Australia’s colonial past, in particular the mistreatment of its Indigenous peoples. The latest of these, Michael R. Griffiths, highlights the shortcomings of Winton’s treatment of this theme, contending that Winton’s text might be read as a kind of excuse, in the name of naïveté, for colonial abuses. Given that Nicholas Birns (among others) has noted a new maturity in Winton’s work from The Turning (2004) onward, a fresh examination of such themes in Winton’s work is warranted. This essay does so through a reading of the short story ‘Aquifer’. Examining the story’s treatment of the psychology of guilt and debt, the essay explores how Winton tries to resolve the moral and historical problems he raises in regard to Australian culture through the ethical figure of the neighbour, drawn in particular from the biblical parable of the Good Samaritan. By showing the centrality of the neighbour to Winton’s work through references to In the Winter Dark (1988), Cloudstreet, Breath (2009), ‘Aquifer,’ and a newspaper editorial by Winton on the humanitarian treatment of refugees, this paper seeks to provide a new critical window through which to understand his evolving ethical ideas about Australia’s past and future.<br/><br/>Journal: Australian Literary Studies<br/>Issue: 32.1<br/>Publisher: Australian Literary Studies, Inc.<br/>Year: 2017<br/>ISSN: 1837-6479',
      },
      {
        title:
          'The Morality Meme: Nietzsche, A Serious Man, and Jewish Enlightenment',
        published:
          'Cultura: International Journal of Philosophy of Culture and Axiology 11.1 (2014): 63-81.',
        image: 'A11.jpg',
        desc: 'Pairing together the Coen brothers film A Serious Man (2009) with the philosophy of Friedrich Nietzsche, this paper looks at questions about morality, illusion, and the influence of Jewish thought on contemporary ethics. Beginning with a reading of Nietzsche that locates his discussion of the Jews within its proper historical context, it traces the beginnings of the ‘morality meme,’ the notion of a universal moral reward that, Nietzsche argues, arises during the Deuteronomist period of Jewish history. The second part of the paper looks at how A Serious Man also engages in an interrogation of this moralistic overcoding of the universe, with a particular emphasis on how these questions upset Larry Gopnik’s views of both science and religion. The essay then concludes with a look at the poisonous effects of the ‘morality meme,’ showing in particular how it has influenced the psychology of anti-Semitism. Rather than a rejection of Jewishness, the paper concludes that in A Serious Man the Coen brothers engage in a careful but loving criticism of their own culture that requires them to distance themselves from the problematic effects of its religious morality.<br/><br/>Journal: Cultura: International Journal of Philosophy of Culture and Axiology<br/>Issue: 11.1<br/>Publisher: Peter Lang<br/>Year: 2014<br/>ISSN: 1584-1057',
      },
      {
        title: 'The Rise of the Fakumentary',
        published:
          'The Journal of Literature and Film (문학과 영상) 15.1 (2014): 247-265.',
        image: 'A12.jpg',
        desc: 'Focusing on two recent films, I’m Still Here (2010) and Exit Through the Gift Shop (2010), this essay looks at the rise of a new cinematic genre: the fakumentary. The fakumentary is qualitatively different from the mockumentary because its satirical intentions are not made visible - on the surface, the fakumentary simulates a real documentary. Considering the rise of this particular style in the broader context of cinema, the essay examines how these recent films play with subjectivity through the use of doubles, masks, and anonymity, in order to pose some searching political questions about the nature of propaganda and the place of critical thought in modern democracy.<br/><br/>Journal: The Journal of Literature and Film (문학과 영상)<br/>Issue: 15.1<br/>Publisher: Korean Association of Literature and Film<br/>Year: 2014<br/>ISSN: 1229-1847',
      },
      {
        title:
          'Fool That You Are: Subverting the Reader in Robbins’s Half Asleep in Frog Pajamas',
        published: 'The Journal of Popular Culture 46.5 (2013): 991-1007.',
        image: 'A13.jpg',
        desc: 'This paper looks at the way in which Robbins uses second person in his novel Half Asleep in Frog Pajamas (1994) in order to subvert the reader. I argue that Robbins, who is notorious for his alternative views on society and his lack of shyness in proclaiming them, is not quite so obvious in the way he presents arguments as he might seem at first. Aware of the fact that simply telling people to think for themselves does not lead to true independence, I examine some of the strategies by which Robbins tricks his readers into thinking critically.<br/><br/>Journal: The Journal of Popular Culture<br/>Issue: 46.5<br/>Publisher: Wiley-Blackwello<br/>Year: 2013<br/>ISSN: 0022-3840',
      },
      {
        title:
          'On the Genealogy of Democracy: Reading Peter Carey’s Parrot and Olivier in America',
        published: 'Australian Literary Studies 27.2 (2012): 68-80.',
        image: 'A14.jpg',
        desc: 'Peter Carey’s novel Parrot and Olivier in America (2009) juxtaposes its two main characters, as one might expect, along class lines. Olivier is a privileged aristocrat and Parrot a rather colorful commoner, a device that Carey uses to provide the reader with an alternating perspective on their lives and times. There are clear parallels between Olivier’s chosen task of documenting what life is like in post-Revolutionary America and the work of Alexis de Tocqueville, but the particular concerns of both characters are in a sense subsumed by a debate that, although it has almost disappeared from political discussions, has never really been resolved. This debate centers on whether democracy is truly an effective and ethical form of government. Democracy, after all, makes the rather large assumption that the people are capable not just of ruling, but ruling wisely. Carey thus asks the reader to ponder some searching questions. What happens to culture in a democratic society? Is democracy possible, or is the descent into mob rule inevitable? Is the power now exercised by the bourgeois really any more just than that of the class they displaced? Carey’s novel is a powerful meditation on the ambivalence of democracy, reframing the unresolved questions of the revolutionary era in such a way that we understand their continuing, hidden force in our lives today.<br/><br/>Journal: Australian Literary Studies<br/>Issue: 27.2<br/>Publisher: Australian Literary Studies, Inc.<br/>Year: 2012<br/>ISSN: 1837-6479',
      },
      {
        title: 'The Transnational Fantasy: The Case of James Cowan',
        published:
          'Antipodes: A Global Journal of Australian/New Zealand Literature 26.1 (2012): 63-68.',
        image: 'A15.jpg',
        desc: 'James Cowan is an Australian author best known for two things: his extensive writings on indigenous Australian culture (the first phase of his writing) and his transnational approach to literature (the second phase), visible in such works as A Mapmaker’s Dream (1998). This paper looks at the deeply problematic way in which Cowan approaches indigenous culture, positing that his shift toward the transnational is a fascinating but ultimately flawed attempt to escape the clutches of the State.<br/><br/>Journal: Antipodes: A Global Journal of Australian/New Zealand Literature<br/>Issue: 26.1<br/>Publisher: American Association of Australasian Literary Studies/Wayne State University Press<br/>Year: 2012<br/>ISSN: 2331-9089',
      },
      {
        title: 'The Virtue of Self-Discipline: Reading Tsiolkas and Foucault',
        published: 'Westerly 57.1 (2012): 209-223.',
        image: 'A16.jpg',
        desc: 'In The Slap (2008), Christos Tsiolkas presents the reader with an array of characters beset by various addictions and compulsions, which they attempt to correct with a series of disciplinary actions that are directed both at others and themselves - the slapping of the young boy, Hugo, is the most obvious example. This essay draws on Michel Foucault’s observation in Chapter 4 of The History of Sexuality: An Introduction that the bourgeois class uses discipline, especially sexual discipline, as a means of leveraging power, and not simply as a tool of repression. This paper looks at the ambivalence of Foucault’s insight as it manifests itself in Tsiolkas’s novel, which presents a window onto an Australian society that destroys its own capacity for joy and happiness through the very mechanisms it employs to seek those things.<br/><br/>Journal: Westerly<br/>Issue: 57.1<br/>Publisher: Westerly Centre (University of Western Australia)<br/>Year: 2012<br/>ISSN: 0043-432X',
      },
      {
        title:
          'After the Victorians: The Historical Turning Point in McEwan’s On Chesil Beach',
        published:
          'Critique: Studies in Contemporary Fiction 53.1 (2012): 82-91.',
        image: 'A17.jpg',
        desc: 'This article examines the common view that Ian McEwan’s novel On Chesil Beach (2007) is an affirmation of the sexual revolution of the 1960s. Instead, the roots of McEwan’s ideas are traced back to the Victorian period, locating On Chesil Beach in a thematic tradition with a long pedigree in modern British literature. Through a critical engagement with McEwan’s oeuvre, the author examines the moral implications that accompany the notion of a historical turning point.<br/><br/>Journal: Critique: Studies in Contemporary Fiction<br/>Issue: 53.1<br/>Publisher: Taylor & Francis<br/>Year: 2012<br/>ISSN: 0011-1619',
      },
      {
        title: 'Postcolonial Impressions in Jonathan Bennett’s Verandah People',
        published:
          'Journal of Commonwealth and Postcolonial Studies 17.2 (2011): 26-38.',
        image: 'A18.jpg',
        desc: 'Focusing on the short story collection Verandah People (2003) by Canadian-Australian author Jonathan Bennett, this paper examines the ways in which postcolonial issues permeate Bennett’s writings about Australia and its landscape. The essay explores these impressions from the perspective of the verandah as a threshold (or hybrid) phenomenon, as a key moment or memory that reveals something about the inner emotional lives of Bennett’s characters. Beyond that, however, it looks at how the impression is also an act of violence, something that has physical force, like Bennett’s metaphor of the tree bleeding sap from a nail wound, or the beach pockmarked by hail stones at the end of the collection.<br/><br/>Journal: Journal of Commonwealth and Postcolonial Studies<br/>Issue: 17.2<br/>Publisher: Georgia Southern University<br/>Year: 2011<br/>ISSN: 1073-1687',
      },
      {
        title: 'Metaphors for Poetry: Reflections on W.B. Yeats’s A Vision',
        published: 'The Yeats Journal of Korea 33 (2011): 83-90.',
        image: 'A19.jpg',
        pdf: 'none',
        desc: 'This paper seeks to look beyond the mystical elements in W. B. Yeats’s A Vision in order to consider what it reveals about the creative process. A Vision arrives at a crucial moment, wedged historically between Yeats’s interests in the occult and emerging secular explanations (Freud’s theory of the unconscious, for instance) of what lies ‘beyond’ the edges of human consciousness. More than just providing particular ‘metaphors for poetry,’ as Yeats’s ‘instructors’ put it, A Vision thus provides a fascinating insight into the way in which poetic inspiration comes about.<br/><br/>Journal: The Yeats Journal of Korea<br/>Issue: 36<br/>Publisher: The Yeats Society of Korea<br/>Year: 2011<br/>ISSN: 1226-4946',
      },
      {
        title:
          'Misunderstanding the Other: Colonial Fantasies in Japanese Story',
        published:
          'Antipodes: A North American Journal of Australian Literature 23.2 (2009): 169-73.',
        image: 'A20.jpg',
        desc: 'This paper examines the problem of cross-cultural communication in Australian cinema, focusing in particular on Sue Brooks’s award-winning film Japanese Story (2003). Reading the film’s central romance as an allegory of Australia’s cultural interactions with Japan, I seek to explore the nuances of Australia’s subordinate position in this arrangement. Through her affair with Hiromitsu, Sandy, the film’s protagonist, experiences the full ambivalence of the postcolonial condition - the desire for collaborative power that underpins it, and the realization that cultural boundaries are a hurdle to intimacy so strong that they may never be overcome.<br/><br/>Journal: Antipodes: A Global Journal of Australian/New Zealand Literature<br/>Issue: 23.2<br/>Publisher: American Association of Australasian Literary Studies/Wayne State University Press<br/>Year: 2009<br/>ISSN: 2331-9089',
      },
      {
        title:
          'Be My Enemy for Friendship’s Sake: Iain Pears’s The Dream of Scipio',
        published:
          'Critique: Studies in Contemporary Fiction 48.3 (2007): 318-28.',
        image: 'A21.jpg',
        desc: 'Drawing its title from William Blake, this paper explores the question of friendship in Iain Pears’s novel, The Dream of Scipio (2002). Using three overlapping historical narratives, Pears traces how the classical understanding of friendship, grounded in a logic of commonly held values, has given birth to a civilization that is both exclusionary and unethical. He makes his case by using the history of anti-Semitism to show how routine persecution of the Jews culminating in the Holocaust became a way to solidify communities in the face of such crises as barbarian invasions, the Black Death, and economic decline. The purpose of this essay is to examine Pears’s critique of the limitations of civilization and to outline his vision of a new perception of friendship that understands and values the ethical and pragmatic importance of difference.<br/><br/>Journal: Critique: Studies in Contemporary Fiction<br/>Issue: 48.3<br/>Publisher: Taylor & Francis<br/>Year: 2007<br/>ISSN: 0011-1619',
      },
      {
        title:
          'The Pornography of Destruction: Performing Annihilation in The Dying Animal',
        published: 'Philip Roth Studies 3.1 (2007): 44-55.',
        image: 'A22.jpg',
        desc: 'Reviewers and critics of The Dying Animal (2001) have tended to see this novella as a work of pornography in the prosaic sense, accusing Roth of creating a tired simulacrum of his earlier, disturbing examinations of human sexuality. This essay, by contrast, argues that The Dying Animal is Roth’s exploration of an existential ‘pornography of destruction,’ one that both encompasses and transcends the realm of the sexual in its fascination with witnessing the event of its own death.<br/><br/>Journal: Philip Roth Studies<br/>Issue: 3.1<br/>Publisher: Purdue University Press<br/>Year: 2007<br/>ISSN: 1547-3929',
      },
      {
        title:
          'An Open Invitation, or How to Read the Ethics of Austen’s Pride and Prejudice',
        published: 'Persuasions: The Jane Austen Journal 29 (2007): 245-54. ',
        image: 'A23.jpg',
        desc: 'Drawing from Jacques Derrida’s essay ‘Passions,’ this paper looks at the notion of the open invitation in Jane Austen’s Pride and Prejudice (1813), focusing in particular on the way that an invitation places its participants in an ambiguous place that lies somewhere between desire and obligation. The argument focuses on the way in which Austen, and in turn her characters, use the idea of the open invitation as an ethical measure of passion’s authenticity.<br/><br/>Journal: Persuasions: The Jane Austen Journal<br/>Issue: 29<br/>Publisher: The Jane Austen Society of North America<br/>Year: 2007<br/>ISSN: 1547-3929',
      },
      {
        title: 'The Mandatory Proxy: Godard’s Vivre sa Vie',
        published:
          'Biography: An Interdisciplinary Quarterly 29.1 (2006): 43-53.',
        image: 'A24.jpg',
        desc: 'This article explores how Jean-Luc Godard’s film Vivre sa Vie (1962) sets about deconstructing—rather than reproducing—the autobiographical act within cinema. Central to Godard’s exercise is the decision to cast Anna Karina, his wife at the time, as the lead actress. Godard repeatedly demonstrates that the cinematic image functions as an opaque screen, a ‘mandatory proxy,’ between actor and viewer that renders a truly authentic autobiography impossible.<br/><br/>Journal: Biography: An Interdisciplinary Quarterly<br/>Issue: 29.1<br/>Publisher: University of Hawai’i Press<br/>Year: 2006<br/>ISSN: 0162-4962',
      },
      {
        title: 'The Impression of a Deeper Darkness: Ian McEwan’s Atonement',
        published: 'English Studies in Canada 32.1 (2006): 147-60.',
        image: 'A25.jpg',
        desc: 'Published in a special issue on the topic of guilt, this analysis of Ian McEwan’s novel Atonement (2001) looks at the ethical problems that emerge from Briony’s narrative. It focuses especially on the problem of the relationship between the form and content of a secret, and the way that this transforms the act of testimony into a problematic discourse.<br/><br/>Journal: English Studies in Canada<br/>Issue: 32.1<br/>Publisher: Association of Canadian College and University Teachers of English<br/>Year: 2006<br/>ISSN: 1913-4835',
      },
      {
        title: 'Greeks and Germans: Rethinking D.H. Lawrence’s The Trespasser',
        published: 'Études lawrenciennes 33 (2005): 253-68.',
        image: 'A26.jpg',
        desc: 'A reexamination of D.H. Lawrence’s second novel, The Trespasser, in the light of recent commentary connecting it to the work of Friedrich Nietzsche.<br/><br/>Journal: Études lawrenciennes<br/>Issue: 33<br/>Publisher: Université Paris Nanterre<br/>Year: 2005<br/>ISSN: 0994-5490',
      },
      {
        title: 'Variations on a Theme: Performing Ishiguro’s The Unconsoled',
        published: 'Interactions 14.2 (2005): 77-88.',
        image: 'A27.jpg',
        desc: 'Drawing from Roland Barthes the notion that the reader ‘performs’ the text each time it is read, this paper analyzes how Ishiguro’s experimental novel uses a similar strategy in portraying the protagonist Ryder, a professional pianist.<br/><br/>Journal: Interactions<br/>Issue: 14.2<br/>Publisher: Ege University<br/>Year: 2005<br/>ISSN: 1300-574X',
      },
      {
        title: 'Myth and Unity in Mansfield’s ‘At the Bay’',
        published: 'Journal of New Zealand Literature 23.2 (2005): 47-61.',
        image: 'A28.jpg',
        desc: 'A look at how Katherine Mansfield engages Greek mythology in her story ‘At the Bay’ as a way of criticizing the overbearing rationality of modernity.<br/><br/>Journal: Journal of New Zealand Literature<br/>Issue: 23.2<br/>Publisher: Victoria University of Wellington<br/>Year: 2005<br/>ISSN: 0112-1227',
      },
      {
        title: 'Diagnosing Chuck Palahniuk’s Fight Club',
        published:
          'Stirrings Still: The International Journal of Existential Literature 2.2 (2005): 81-104.',
        image: 'A29.jpg',
        desc: 'Arguing against the prevalent but mistaken notion that Tyler Durden is the mouthpiece of Chuck Palahniuk, this paper examines the underlying critique of politics that underlies Fight Club. It focuses in particular on how Palahniuk takes aim at an underlying fascism in contemporary society that has little to do with conventional definitions of left and right.<br/><br/>Journal: Stirrings Still: The International Journal of Existentialist Literature<br/>Issue: 2.2<br/>Publisher: Binghamton University<br/>Year: 2005<br/>ISSN: 1551-0433',
      },
      {
        title: 'Spinoza’s Stone: The Logic of Donnie Darko',
        published:
          'Post Script: Essays in Film and the Humanities 25.1 (2005): 38-48.',
        image: 'A30.jpg',
        desc: 'This paper examines the self-reflexive way in which Donnie Darko utilizes the ‘life-line’ as as an example of how modern society reduces human experiences to a simplistic and antagonistic division between good and bad. While the viewer understands and accepts the film’s argument about the complexity of life and morality when this antagonistic structure is made explicit, the same cannot be said for how the film’s ending has been received, whereby the meaning of Donnie’s death is regularly narrowed down, yet again, to a simple binary.<br/><br/>Journal: Post-Script: Essays in Film and the Humanities<br/>Issue: 25.1<br/>Publisher: Texas A&M University-Commerce<br/>Year: 2005<br/>ISSN: 0277-9897',
      },
      {
        title:
          'A Farewell to Goodbyes: Reconciling the Past in Cheever’s ‘Goodbye, My Brother’',
        published: 'Journal of the Short Story in English 43 (2004): 107-20.',
        image: 'A31.jpg',
        desc: 'This paper involves an in-depth reading of John Cheever’s short story ‘Goodbye, My Brother,’ focusing on the way in which the narrator struggles with - and finally reconciles - the question of paternal discourses, ranging from God through to his own late father.<br/><br/>Journal: Journal of the Short Story in English<br/>Issue: 43<br/>Publisher: Belmont University, Nashville/Centre de recherches inter-langues, Université d’Angers<br/>Year: 2004<br/>ISSN: 1969-6108',
      },
      {
        title: 'Music in His Own Image: The Aphex Twin Face',
        published: 'Nebula 1.1 (2004): 65-73.',
        link: 'http://www.nobleworld.biz/images/ARTL8.pdf',
        image: 'A32.jpg',
        desc: 'Moving from a consideration of how Aphex Twin populates his videos with his own image, this paper culminates with a consideration of how this artist secretly inserts a sound-image of his face into one of his compositions.<br/><br/>Journal: NEBULA: A Journal of Multidisciplinary Scholarship<br/>Issue: 1.1<br/>Publisher: S. Habib<br/>Year: 2004<br/>ISSN: 1449-7751',
      },
      {
        title: 'That Which Exceeds',
        published:
          'The Dostoevsky Journal: An Independent Review 3&4 (2002-3): 67-78.',
        image: 'A33.jpg',
        desc: 'A reading of Dostoevsky’s novel The Devils that looks at the various ways in which the author points beyond the boundaries of the text.<br/><br/>Journal: The Dostoevsky Journal: An Independent Review<br/>Issue: 3-4<br/>Publisher: Charles Schlacks, Jr.<br/>Year: 2003<br/>ISSN: 1535-5314',
      },
      {
        title: "The Equivocation of Meaning in Stendhal's Realism",
        published:
          'Limina: A Journal of Historical and Cultural Studies 6 (2000): 100-115.',
        image: 'A34.jpg',
        desc: 'An examination of the complex overlapping ideologies at work across Stendhal’s texts.<br/><br/>Journal: Limina: A Journal of Cultural and Historical Studies<br/>Issue: 6<br/>Publisher: University of Western Australia<br/>Year: 2000<br/>ISSN: 1324-4558',
      },
      {
        title: "Dostoevsky's The Double, Postmodernism and the Neo-Baroque",
        published:
          'The Dostoevsky Journal: An Independent Review 1 (2000): 29-42.',
        image: 'A35.jpg',
        desc: 'A paper that connects Gilles Deleuze’s notions of multiplicity in Leibniz to Dostoevsky’s early novel The Double.<br/><br/>Journal: The Dostoevsky Journal: An Independent Review<br/>Issue: 1<br/>Publisher: Charles Schlacks, Jr.<br/>Year: 2000<br/>ISSN: 1535-5314',
      },
      {
        title: 'Dostoevsky: Expressions in Silence',
        published:
          'The Dostoevsky Journal: An Independent Review 1 (2000): 1-9. (Co-authored with Bryan Cooke)',
        image: 'A36.jpg',
        desc: 'An affirmation of Bakhtin’s strategy of reading Dostoevsky as a multi-layered and complex author.<br/><br/>Journal: The Dostoevsky Journal: An Independent Review<br/>Issue: 1<br/>Publisher: Charles Schlacks, Jr.<br/>Year: 2000<br/>ISSN: 1535-5314',
      },
    ],
  },
  {
    category: 'Book Chapters',
    info: [
      {
        title: 'Turning the Inside Out: Interiority and Australian Fiction',
        published:
          'The Routledge Companion to Australian Literature. Ed. Jessica Gildersleeve. New York: Routledge. 2021: 227-234',
        image: 'B8.jpg',
        pdf: 'none',
        desc: 'Book: The Routledge Companion to Australian Literature<br/>Editor: Jessica Gildersleeve<br/>Publisher: Routledge<br/>Year: 2021<br/>ISBN: 9780367643560',
      },
      {
        title:
          'The Reluctant Character of Mr. Darcy: Pride and Prejudice and the Rise of the Novel',
        published:
          'Pride and Prejudice: A Bicentennial Bricolage. Ed. Caterina Colomba. Udine, Italy: Forum University Press. 2016: 119-134.',
        image: 'B1.jpg',
        desc: 'This essay aims to locate Jane Austen’s Pride and Prejudice in the context of the rise of the novel, with a particular focus on the character of Mr. Darcy. Mr. Darcy’s reluctance to express himself comes from his aristocratic sense of himself, a mode of thinking that had been rendered increasingly obsolete by the changing social conditions of the eighteenth century. Thus, as Austen’s story unfolds, Mr. Darcy gradually learns that, if he is to wield power and win Elizabeth’s love, he must not only possess certain virtues, but also produce and inscribe them onto the world.<br/><br>Book: Pride and Prejudice: A Bicentennial Bricolage<br/>Editor: Caterina Colomba<br/>Publisher: Forum University Press<br/>Year: 2016<br/>ISBN: 9788884208330',
      },
      {
        title:
          'Assessing the Moral Landscape: Postcolonial Violence in Australian Cinema',
        published:
          'Spectacles of Blood: A Study of Violence and Masculinity in Postcolonial Films. Ed. Swaralipi Nandi and Esha Chatterjee. New Dheli: Zubaan, 2012: 51-63.',
        image: 'B2.jpg',
        desc: 'While the landscape appears as a ubiquitous signifier in Australian film, its symbolic function has changed from the New Wave films of the 1970s as Australian cinema has moved into the twenty-first century. Focusing on the intertwining issues of gender and postcoloniality, this paper uses two films - Peter Weir’s Picnic at Hanging Rock (1975) and Greg McLean’s Wolf Creek (2005) - as representative moments in the evolution of these discourses. In the three decades that divide these films, this essay traces the way in which the imperatives of commercialism have impacted on Australia’s cinematic portrayal of its national myths.<br/><br/>Book: Spectacles of Blood: A Study of Violence and Masculinity in Postcolonial Films<br/>Editors: Swaralipi Nandi and Esha Chatterjee<br/>Publisher: Zubaan Books<br/>Year: 2012<br/>ISBN: 9789381017159',
      },
      {
        title:
          'An Open Invitation, or How to Read the Ethics of Austen’s Pride and Prejudice',
        published:
          'Pride and Prejudice: Critical Insights. Ed. Lawrence W. Mazzeno. Pasadena, CA: Salem, 2012: 368-382.',
        image: 'B3.jpg',
        pdf: 'none',
        desc: 'Drawing from Jacques Derrida’s essay “Passions,” this paper looks at the notion of the open invitation in Jane Austen’s Pride and Prejudice (1813), focusing in particular on the way that an invitation places its participants in an ambiguous place that lies somewhere between desire and obligation. The argument focuses on the way in which Austen, and in turn her characters, use the idea of the open invitation as an ethical measure of passion’s authenticity.<br/><br/>Book: Jane Austen: Critical Insights<br/>Editors: Laurence W. Mazzeno<br/>Publisher: Salem Press<br/>Year: 2012<br/>ISBN: 9781587658433<br/><br/>This chapter is a reprint of my 2007 journal article in Persuasions. Please see above for the full text.',
      },
      {
        title:
          'A Farewell to Goodbyes: Reconciling the Past in Cheever’s ‘Goodbye, My Brother’',
        published:
          'John Cheever: Critical Insights. Ed. Robert A. Morace.  Pasadena, CA: Salem, 2012: 331-345.',
        image: 'B4.jpg',
        pdf: 'none',
        desc: "This paper involves an in-depth reading of John Cheever's short story 'Goodbye, My Brother,' focusing on the way in which the narrator struggles with - and finally reconciles - the question of paternal discourses, ranging from God through to his own late father.<br/><br/>Book: John Cheever: Critical Insights<br/>Editors: Robert A. Morace<br/>Publisher: Salem Press<br/>Year: 2012<br/>ISBN: 9781587658273<br/><br/>This chapter is a reprint of my 2004 journal article in Journal of the Short Story in English.  Please see above for the full text.",
      },
      {
        title:
          'The Postcolonial Screen: Elaborate Forgeries in Rodney Hall’s The Second Bridegroom',
        published:
          'Postcolonial Issues in Australian Literature. Ed. Nathanael O’Reilly. Amherst, NY: Cambria, 2010: 237-253.',
        image: 'B5.jpg',
        desc: 'Appearing in an edited collection about postcolonial issues in Australian literature, this paper looks at the theme of forgery in The Second Bridegroom (1991), a novel by Rodney Hall, two-time winner of the Miles Franklin Award. I examine, in particular, the way in which the reader is gradually fed clues that neither the unnamed protagonist, sentenced to the colonies for an elaborate forgery, nor his memoirs are what they seem to be. Indeed, by the end of the narrative, the reader ought to have serious doubts as to the authenticity of both – rather than having been written by their purported author, this memoir seems instead to be the work of the addressee, a woman looking to escape her tyrannical husband. The story thus functions not only as a forgery, but as a distraction, a ‘screen’ behind which one can hide from the gaze of the colonial master.<br/><br/>Book: Postcolonial Issues in Australian Literature<br/>Editor: Nathanael O’Reilly<br/>Publisher: Cambria Press<br/>Year: 2010<br/>ISBN: 9781604977110',
      },
      {
        title:
          'A Subterranean Dialogue with Nietzsche on the Demonic and the Divine in The Sea, the Sea',
        published:
          'Iris Murdoch and the Moral Imagination. Ed. Simone Roberts and Alison Scott-Baumann. Jefferson, NC: McFarland & Co., 2010: 190-202.',
        image: 'B6.jpg',
        desc: 'Beginning with the argument that Iris Murdoch, while rarely confronting Nietzsche directly, nonetheless engages him in a ‘subterranean’ dialogue in her work, this paper takes Murdoch’s Booker Prize-winning novel The Sea, the Sea (1978) as a prime example. Divided into three sections, the paper compares Murdoch’s and Nietzsche’s views on acting, women, and marriage in order to show the different ways they harness the demonic power of critique and the redemptive power of the divine.<br/><br/>Book: Iris Murdoch and the Moral Imagination<br/>Editors: M.F. Simone Roberts and Alison Scott-Baumann<br/>Publisher: McFarland Publishing<br/>Year: 2010<br/>ISBN: 9780786440269',
      },
      {
        title: 'The Politics of Voice in Palahniuk’s Lullaby',
        published:
          'Reading Chuck Palahniuk: American Monsters and Literary Mayhem. Ed. Cynthia Kuhn and Lance Rubin. New York: Routledge, 2009: 157-169.',
        image: 'B7.jpg',
        desc: 'This paper examines the politics of voice in Chuck Palahniuk’s novel Lullaby (2002), which plays on the anonymity of the signifying act in order to call into question the philosophical and legal limits of personal responsibility.<br/><br/>Book: Reading Chuck Palahniuk: American Monsters and Literary Mayhem<br/>Editors: Cynthia Kuhn and Lance Rubin<br/>Publisher: Routledge<br/>Year: 2009<br/>ISBN: 9780415634243',
      },
    ],
  },
];
