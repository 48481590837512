import React from "react";
import Heros from "../components/Heros";
import Cards from "../components/Cards";
import Bottom from "../components/Bottom";

const Home = () => (
    <>
      <Heros />
      <Cards />
      <Bottom />
    </>
  )

export default Home;
